import React, { useState } from 'react'
import BaseShow from '../../Show/BaseShow'
import BaseDatagrid from '../../../react_admin/BaseDatagrid'
import BaseList from '../../List/BaseList'
import PartnerTabBar from './PartnerTabBar'
import PartnerTopToolbar from './PartnerTopToolbar'
import TenantDataGrid from '../../tenants/List/TenantDataGrid'
import UserDatagrid from '../../user/UserDatagrid'
import { JobDataGrid } from '../../jobs/JobsList'
import ActivityFilterToolbar from '../../Show/ActivityFilterToolbar'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../../appConfigs'
import {
    SimpleShowLayout,
    TextField as RATextField,
    useGetList,
    useGetOne,
    useRecordContext,
} from 'react-admin'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import StackedTextField from '../../../StackedTextField'
import { CustomDivider } from '../../../common'
import { get, isEmpty, startCase } from 'lodash-es'
import { useParams } from 'react-router-dom'
import { EmptyState } from '../../../react_admin/EmptyState'

const IntegrationDatagrid = (props) => {
    return (
        <BaseDatagrid rowClick="show" bulkActionButtons={false} {...props}>
            <RATextField source="name" label="Name" />
            <RATextField source="type" label="Type" />
            <RATextField source="repositoryPath" label="Repository Path" />
            <RATextField
                source="repositoryTrackingBranch"
                label="Repository Tracking Branch"
            />
            <RATextField source="repositoryUrl" label="Repository URL" />
            <RATextField source="defaultReleaseId" label="Default Release ID" />
        </BaseDatagrid>
    )
}

// this is broken out into its own component to stop the
// baseShow props (for the partner resource) from taking over
const RunsList = ({ partnerId }) => (
    <BaseList
        resource="runs"
        basePath="/runs"
        perPage={SUBGRID_RECORDS_PER_PAGE}
        filters={<ActivityFilterToolbar />}
        actions={null}
        TopToolbar={null}
        filter={{ partner_id: partnerId, integration__type: 'INTERNAL' }}
        sort={{ field: 'startedDate', order: 'DESC' }}
    >
        <JobDataGrid />
    </BaseList>
)

const useGeneralCardStyles = makeStyles({
    thumbnail: {
        height: '30px',
        width: '30px',
        paddingRight: '2px',
    },
    thumbnailWithName: {
        display: 'flex',
        flexDirection: 'row',
        wordBreak: 'break-all',
    },
    statusFlag: {
        maxWidth: '170px',
        minHeight: '32px',
        borderRadius: '16px',
        backgroundColor: (props) =>
            props.partnerStatus === 'APPROVED' ? '#08C988' : '#B25EFC',
        fontSize: '15px',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardField: {
        overflowWrap: 'break-word',
    },
})
const GeneralCard = () => {
    const record = useRecordContext()
    const partnerStatus = get(record, 'approvalStatus', 'PENDING').toUpperCase()
    const classes = useGeneralCardStyles({ partnerStatus })
    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <StackedTextField label="ORGANIZATION">
                    <div className={classes.thumbnailWithName}>
                        {record.settings.secondaryLogo && (
                            <img
                                className={classes.thumbnail}
                                src={record.settings.secondaryLogo}
                                alt=""
                            />
                        )}
                        {record.name}
                    </div>
                </StackedTextField>
            </Grid>
            <Grid item xs={3} className={classes.cardField}>
                <StackedTextField label="PRIMARY CONTACT">
                    {get(record, 'primaryUser.email', 'N/A')}
                </StackedTextField>
            </Grid>
            <Grid item xs={3} className={classes.cardField}>
                <StackedTextField label="LAST ACTIVITY">
                    {/* TODO Replace with functionality of displaying last run date? */}
                    {'n/a'}
                </StackedTextField>
            </Grid>
            <Grid item xs={3} className={classes.cardField}>
                <StackedTextField label="STATUS">
                    <div className={classes.statusFlag}>{partnerStatus}</div>
                </StackedTextField>
            </Grid>
        </Grid>
    )
}

const usePartnerShowStyles = makeStyles({
    question: {
        fontFamily: 'roboto condensed',
        fontSize: '16px',
        lineHeight: '28px',
        color: '#707279',
        marginRight: '35px',
    },
    answer: {
        fontFamily: 'roboto condensed',
        fontSize: '16px',
        lineHeight: '28px',
        color: '#000000',
    },
})

const PartnerShow = ({ userId }) => {
    const [tabValue, setTabValue] = useState(0)
    const classes = usePartnerShowStyles()
    const { id: partnerId } = useParams()

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const { data: partner } = useGetOne('partners', { id: partnerId })

    const partnerIntakeForm = partner?.partnerIntakeForm
    const partnerName = partner?.name

    const { data: groups } = useGetList('groups', {
        filter: { name: `${partnerId}--${partnerName}` },
    })

    const group = groups && Object.values(groups)[0]

    // TODO Update later when implementing generic boolean control
    const formatQuestion = (question, schema) =>
        schema.properties[question].type === 'boolean'
            ? 'Accept Terms and Conditions'
            : startCase(question)

    return (
        <BaseShow
            basePath="/partners"
            resource="partners"
            filter={{ org_type: 'PARTNER' }}
            id={partnerId}
            actions={null}
            TopToolbar={
                <PartnerTopToolbar partnerId={partnerId} groupId={group?.id} />
            }
        >
            <SimpleShowLayout>
                <GeneralCard />
                <CustomDivider sx={{ margin: '24px 0 0 0' }} />
                <PartnerTabBar
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                />
                {tabValue === 0 && (
                    <BaseList
                        resource="tenants"
                        basePath="/tenants"
                        perPage={SUBGRID_RECORDS_PER_PAGE}
                        actions={null}
                        TopToolbar={null}
                        filter={{
                            partner_id: partnerId,
                            integration__type: 'INTERNAL',
                        }}
                        sort={{ field: 'createdDate', order: 'DESC' }}
                    >
                        <TenantDataGrid
                            bulkActionButtons={false}
                            empty={
                                <EmptyState
                                    emptyStateText={
                                        'Welcome to Pandium!\nCreate a tenant for one of your integrations!'
                                    }
                                />
                            }
                        />
                    </BaseList>
                )}
                {tabValue === 1 && <RunsList partnerId={partnerId} />}
                {tabValue === 2 && (
                    <BaseList
                        resource="integrations"
                        basePath="/integrtions"
                        perPage={SUBGRID_RECORDS_PER_PAGE}
                        actions={null}
                        TopToolbar={null}
                        filter={{ partner_id: partnerId }}
                        sort={{ field: 'createdDate', order: 'DESC' }}
                    >
                        <IntegrationDatagrid
                            empty={
                                <EmptyState
                                    emptyStateText={
                                        'Welcome to Pandium!\nCreate an integration to get started!'
                                    }
                                />
                            }
                        />
                    </BaseList>
                )}
                {tabValue === 3 && (
                    <BaseList
                        resource="users"
                        basePath="/users"
                        perPage={SUBGRID_RECORDS_PER_PAGE}
                        actions={null}
                        TopToolbar={null}
                        filter={{ partner_id: partnerId }}
                        sort={{ field: 'firstName', order: 'ASC' }}
                    >
                        <UserDatagrid
                            userId={userId}
                            empty={
                                <EmptyState
                                    emptyStateText={
                                        'Welcome to Pandium!\nThere are no users yet!'
                                    }
                                />
                            }
                        />
                    </BaseList>
                )}
                {tabValue === 4 &&
                    partnerIntakeForm &&
                    !isEmpty(partnerIntakeForm) &&
                    Object.keys(partnerIntakeForm.answers).map((question) => (
                        <Grid container key={question}>
                            <Grid item xs={2} className={classes.question}>
                                {formatQuestion(
                                    question,
                                    partnerIntakeForm.schema
                                )}
                            </Grid>
                            <Grid item xs={2} className={classes.answer}>
                                {partnerIntakeForm.answers[question].toString()}
                            </Grid>
                        </Grid>
                    ))}
            </SimpleShowLayout>
        </BaseShow>
    )
}

export default PartnerShow
