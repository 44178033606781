import React, { useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import SideBar from './Sidebar'
import { Box } from '@mui/material'
import AppBar from '../AppBar/AppBar'
import { getOrgAction } from '../../customActions'
import PartnerIntake from '../PartnerIntake'
import { ThemeProvider } from '@mui/styles'
import { muiV5Theme } from '../../appConfigs'
import { useSidebarState } from 'react-admin'
import AccountSelector from '../AccountSelector'
import { EmptyState } from './EmptyState'

const withRouter = (Component) => {
    const Wrapper = (props) => {
        const location = useLocation()
        const navigate = useNavigate()
        const params = useParams()

        return <Component router={{ location, navigate, params }} {...props} />
    }

    return Wrapper
}

const pandiumLayoutStyles = (theme) => ({
    appFrame: (isSuperUser) => ({
        '& .MuiAppBar-positionFixed': {
            top: isSuperUser ? 56 : 0,
        },
    }),
    contentWithSidebar: (isSuperUser) => ({
        display: 'flex',
        flexGrow: 1,
        backgroundColor: 'white',
        paddingTop: isSuperUser ? '56px' : '',
    }),
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 0,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        marginTop: '4em',
    },
    loader: {
        position: 'fixed',
        bottom: '100px',
        left: '70px',
        margin: 25,
        zIndex: 1200,
        width: '2em',
        height: '2em',
        color: 'white',
    },
    pageTitle: {
        fontFamily: 'RobotoCondensedBold',
        margin: '20px 0 16px 0px',
    },
})

const PandiumLayout = ({
    children,
    logout,
    title,
    username,
    userId,
    orgId,
    isParentOrg,
    parentOrgName,
    parentId,
    orgApprovalStatus,
    isSuperUser,
}) => {
    const intakeFormRequired = orgApprovalStatus !== 'Approved'
    const isSuperWithoutAccount = isSuperUser && !orgId

    const dispatch = useDispatch()
    const [open] = useSidebarState()
    useEffect(() => {
        if (orgId) {
            dispatch(getOrgAction(orgId, parentId, !!isParentOrg, isSuperUser))
        }
    }, [dispatch, isParentOrg, orgId, parentId, isSuperUser])

    if (!isParentOrg) {
        // we need to check the approval status of the organization
        // of every partner user every time they log in, adding a moment
        // of blank screen to the login experience.
        if (intakeFormRequired === undefined) {
            return <div />
        } else if (intakeFormRequired) {
            return <PartnerIntake />
        }
    }

    return (
        <Box sx={(theme) => pandiumLayoutStyles(theme).appFrame(isSuperUser)}>
            {isSuperUser && (
                <AccountSelector
                    current={orgId + '--' + parentOrgName}
                    username={username}
                    userId={userId}
                />
            )}
            <AppBar
                username={username}
                user_id={userId}
                logout={logout}
                title={title}
                open={open}
            />
            {isSuperWithoutAccount ? (
                <EmptyState emptyStateText="Please select an account." />
            ) : (
                <Box
                    sx={(theme) =>
                        pandiumLayoutStyles(theme).contentWithSidebar(
                            isSuperUser
                        )
                    }
                >
                    <SideBar logout={logout} title={title} />
                    <Box sx={(theme) => pandiumLayoutStyles(theme).content}>
                        {children}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

PandiumLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    logout: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
    ]),
    title: PropTypes.string.isRequired,
    userId: PropTypes.string,
    orgId: PropTypes.string,
}

const ConnectedLayout = withRouter(PandiumLayout)

export default ({ ...rest }) => {
    return (
        <ThemeProvider theme={muiV5Theme}>
            <ConnectedLayout {...rest} />
        </ThemeProvider>
    )
}
