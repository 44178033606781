import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress,
    Link,
    Tooltip,
    Box,
} from '@mui/material'
import { useDataProvider, useNotify } from 'react-admin'
import { truncate, get, isEmpty } from 'lodash-es'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileCopy } from '@mui/icons-material'
import { JsonFormsWrapper } from '../jsonFormComponents/JsonFormsWrapper'

function requireConnectionTest(connector) {
    return connector.metadata?.test_after_connect
}

const ConnectDialog = ({
    close,
    connector,
    tenant,
    oAuth2Link,
    open,
    modalLoading,
    modalError,
    connectBasic,
    connectRequest,
    autoRedirect = false,
}) => {
    const [connectorStep, setConnectorStep] = useState(1)
    const [jsonFormValues, setJsonFormValues] = useState({})
    const notify = useNotify()
    const closeAndClear = () => {
        close()
        setJsonFormValues({})
    }
    const save = (connectorName, values) => {
        connectBasic(connectorName, values)
        if (!requireConnectionTest(connector)) {
            closeAndClear()
        }
    }
    const next = (connectorName, values) => {
        connectRequest(connectorName, values)
        if (autoRedirect) {
            closeAndClear() // the connect form handles the redirection, here we just need to close the dialog
        } else {
            setConnectorStep(2)
        }
    }
    const [jwtLink, setJWTLink] = useState('')
    const [authButtonToggle, setAuthButtonToggle] = useState(true)
    const dataProvider = useDataProvider()
    const [isJsonFormValid, setIsJsonFormValid] = useState(false)
    const onJsonFormChange = (errors, jsonFormValues) => {
        setJsonFormValues(jsonFormValues)
        isEmpty(errors) ? setIsJsonFormValid(true) : setIsJsonFormValid(false)
    }

    const handleGenerateJWT = () => {
        if (!jwtLink) {
            dataProvider
                .AUTHOR('author/generate_external_jwt', {
                    data: {
                        connectorName: connector.name,
                        tenantId: tenant.id,
                    },
                })
                .then((response) => {
                    setJWTLink(response.data['jwtUrl'])
                    setAuthButtonToggle(false)
                })
                .catch((error) => {
                    console.debug('ERROR GENERATING EXTERNAL LINK', error)
                    notify('Link Generation Failed!', 'error')
                })
        } else {
            setAuthButtonToggle(false)
        }
    }

    const handleReturnToForm = () => {
        setAuthButtonToggle(true)
    }

    const shouldRenderForm =
        get(connector, 'metadata.grant_flow') === 'backend' ||
        get(connector, 'metadata.grant_flow') === 'jwt' ||
        get(connector, 'metadata.grant_flow') === 'legacy' ||
        connector.type === 'basic' ||
        ['ezcom', 'field_nation', 'sage'].includes(connector.name)
    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <div>
                <DialogTitle>Tenant Secrets</DialogTitle>
                {connector.metadata?.enable_external_jwt === true &&
                    connectorStep === 1 &&
                    (authButtonToggle ? (
                        <Tooltip
                            title={
                                'Generates a link for external connector configuration'
                            }
                            placement="top"
                            arrow
                        >
                            <Button
                                style={{ margin: '10px' }}
                                onClick={handleGenerateJWT}
                            >
                                Generate Customer Link
                            </Button>
                        </Tooltip>
                    ) : (
                        <Tooltip title={''} placement="top" arrow>
                            <Button
                                style={{ margin: '10px' }}
                                onClick={handleReturnToForm}
                            >
                                Use Form
                            </Button>
                        </Tooltip>
                    ))}
            </div>
            <DialogContent
                sx={{
                    width: '85%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <DialogContentText>
                    {' '}
                    {get(connector, 'helpText', '')}{' '}
                </DialogContentText>
                {modalError && (
                    <DialogContentText
                        sx={{ marginBottom: '8px' }}
                        color="error"
                    >
                        {modalError}
                    </DialogContentText>
                )}
                {authButtonToggle ? (
                    requireConnectionTest(connector) && modalLoading ? (
                        <Box
                            sx={{
                                textAlign: 'center',
                                margin: '40px 0 0 0',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : // Content differs based on connector type
                    shouldRenderForm && !get(connector, 'jsonrender.schema') ? (
                        <JsonFormsWrapper
                            schema={get(connector, 'jsonform.schema')}
                            uischema={get(connector, 'jsonform.uischema')}
                            data={jsonFormValues}
                            onJsonFormChange={onJsonFormChange}
                            fullWidth={true}
                        />
                    ) : // Render content for oauth2
                    (connector.type === 'oauth2' &&
                          get(connector, 'metadata.multi_step') !== true) ||
                      connector.name === 'ezcom' ||
                      (get(connector, 'metadata.multi_step') === true &&
                          connectorStep === 2) ? (
                        // Render content for backend Oauth 2 connectors that take both tenant level secrets and integration level secrets
                        get(connector, 'metadata.grant_flow') === 'backend' &&
                        get(connector, 'jsonrender.schema') ? (
                            <JsonFormsWrapper
                                schema={get(connector, 'jsonrender.schema')}
                                uischema={get(connector, 'jsonrender.uischema')}
                                data={jsonFormValues}
                                onJsonFormChange={onJsonFormChange}
                                fullWidth={true}
                            />
                        ) : // Render the Oauth 2 link for all other Oauth2 connectors
                        !isEmpty(oAuth2Link) ? (
                            <div>
                                <CopyToClipboard text={oAuth2Link}>
                                    <IconButton>
                                        <FileCopy />
                                    </IconButton>
                                </CopyToClipboard>
                                <Link
                                    href={oAuth2Link}
                                    sx={{ color: '#1976d2' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    {truncate(oAuth2Link, {
                                        length: 40,
                                    })}{' '}
                                </Link>
                            </div>
                        ) : (
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    margin: '40px 0 0 0',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )
                    ) : connector.name === 'linnworks' ? (
                        !isEmpty(oAuth2Link) ? (
                            <div>
                                <DialogContentText>
                                    Please use the link below to generate your
                                    API Key, and then pass that key into the
                                    form below.
                                </DialogContentText>
                                <CopyToClipboard text={oAuth2Link}>
                                    <IconButton>
                                        <FileCopy />
                                    </IconButton>
                                </CopyToClipboard>
                                <Link
                                    href={oAuth2Link}
                                    sx={{ color: '#1976d2' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    {truncate(oAuth2Link, {
                                        length: 40,
                                    })}{' '}
                                </Link>
                                <JsonFormsWrapper
                                    schema={get(connector, 'jsonrender.schema')}
                                    uischema={get(
                                        connector,
                                        'jsonrender.uischema'
                                    )}
                                    data={jsonFormValues}
                                    onJsonFormChange={onJsonFormChange}
                                    fullWidth={true}
                                />
                            </div>
                        ) : (
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    margin: '40px 0 0 0',
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )
                    ) : get(connector, 'metadata.multi_step') === true &&
                      connectorStep === 1 ? (
                        <div>
                            <JsonFormsWrapper
                                schema={get(connector, 'jsonrender.schema')}
                                uischema={get(connector, 'jsonrender.uischema')}
                                data={jsonFormValues}
                                onJsonFormChange={onJsonFormChange}
                                fullWidth={true}
                            />
                        </div>
                    ) : get(connector, 'jsonrender.schema') ? (
                        <div>
                            <JsonFormsWrapper
                                schema={get(connector, 'jsonrender.schema')}
                                uischema={get(connector, 'jsonrender.uischema')}
                                data={jsonFormValues}
                                onJsonFormChange={onJsonFormChange}
                                isConnectorForm={true}
                            />
                        </div>
                    ) : (
                        <div> Under Construction </div>
                    )
                ) : (
                    <div>
                        <DialogContentText>
                            Please use the link below to allow your customers
                            access to enter in their integration keys. Upon
                            submission, a link will be generated for them to
                            authenticate the connection.
                        </DialogContentText>
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <CopyToClipboard text={jwtLink}>
                                <IconButton>
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                            <Link
                                href={jwtLink}
                                sx={{ color: '#1976d2' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                {truncate(jwtLink, {
                                    length: 40,
                                })}{' '}
                            </Link>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                {authButtonToggle && !modalLoading && (
                    <Button
                        onClick={() => {
                            get(connector, 'metadata.multi_step') &&
                            connectorStep === 2
                                ? setConnectorStep(1)
                                : closeAndClear()
                        }}
                    >
                        Back
                    </Button>
                )}
                {(shouldRenderForm || connector.name === 'linnworks') &&
                    authButtonToggle &&
                    !modalLoading && (
                        <Button
                            disabled={!isJsonFormValid}
                            onClick={() => save(connector.name, jsonFormValues)}
                        >
                            Save
                        </Button>
                    )}
                {get(connector, 'metadata.multi_step') === true && (
                    <Button
                        onClick={() => {
                            connectorStep === 1 && authButtonToggle
                                ? next(connector.name, jsonFormValues)
                                : closeAndClear()
                        }}
                    >
                        {connectorStep === 1 && authButtonToggle
                            ? 'Next'
                            : 'Close'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default ConnectDialog
