import React from 'react'
import { LinearProgress, Typography, Grid, Button } from '@mui/material'
import {
    TextInput,
    SelectInput,
    useCreate,
    useNotify,
    useRefresh,
    useGetList,
    useListContext,
} from 'react-admin'
import { get, isEmpty, pickBy } from 'lodash-es'
import BaseDialog from '../../BaseDialog'
import {
    required,
    validateEmail,
    validateInputChoice,
} from '../../inputValidators'
import UserDatagrid from './UserDatagrid'
import { connect } from 'react-redux'
import BaseList from '../List/BaseList'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../appConfigs'
import { EmptyState } from '../../react_admin/EmptyState'
import { useFormContext } from 'react-hook-form'

const userListActionStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
    },
    inputField: {
        width: '100%',
        margin: '0',
    },
    dialogLauncher: {
        fontFamily: 'RobotoCondensedBold',
        color: '#635ee7',
        fontSize: '16px',
        letterSpacing: '3px',
    },
    fullNameWrapper: {
        display: 'flex',
    },
}

export const UserCreateButton = ({ handleSubmit, groups }) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const { getValues, setValue, formState } = useFormContext()
    // username is the same as email at the moment
    setValue('username', getValues().email)
    if (!getValues('groups')) {
        setValue('groups', groups)
    }

    const [create] = useCreate()
    const disabled = !formState.isDirty || !formState.isValid
    return (
        <Button
            className="filledButton"
            onClick={() =>
                create(
                    'users',
                    { data: { ...getValues() } },
                    {
                        onSuccess: (data) => {
                            console.debug('Success: ', data)
                            notify('User Created!')
                            handleSubmit()
                            refresh()
                        },
                        onFailure: (error) => {
                            notify('Error creating User.')
                            console.debug(
                                'Error: ',
                                get(error, 'message', error)
                            )
                            handleSubmit()
                        },
                    }
                )
            }
            disabled={!!disabled}
        >
            Add User
        </Button>
    )
}
const UserListActions = ({ ids, groups, isParentOrg }) => {
    const { data } = useListContext()
    // remove 'partners' group from available groups
    const filteredGroups = pickBy(groups, (key) => {
        return key.name !== 'partners'
    })

    const createDialogInputFields = (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextInput
                        label="First Name"
                        source="firstName"
                        variant="filled"
                        sx={userListActionStyles.inputField}
                        placeholder="Jane"
                        validate={required}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        label="Last Name"
                        source="lastName"
                        variant="filled"
                        sx={userListActionStyles.inputField}
                        placeholder="Puma"
                        validate={required}
                    />
                </Grid>
            </Grid>
            <TextInput
                label="Email"
                source="email"
                variant="filled"
                sx={userListActionStyles.inputField}
                placeholder="janepuma@example.com"
                validate={[required, validateEmail]}
                parse={(v) => v.trim()}
            />
            {isParentOrg && !isEmpty(filteredGroups) && (
                <SelectInput
                    emptyText={'Select One'}
                    emptyValue={-1}
                    label="Organization"
                    source="groups"
                    choices={Object.keys(filteredGroups).map(
                        (filteredGroups, idx) => {
                            return {
                                id: groups[filteredGroups].id,
                                name:
                                    groups[filteredGroups].name === 'admin'
                                        ? groups[filteredGroups].path.split(
                                              '/'
                                          )[1]
                                        : groups[filteredGroups].path.split(
                                              '/'
                                          )[
                                              groups[filteredGroups].path.split(
                                                  '/'
                                              ).length - 1
                                          ],
                            }
                        }
                    )}
                    validate={[required, validateInputChoice]}
                    variant="outlined"
                />
            )}
            {/* TODO: one day roles will matter isParentOrg && (
                <SelectInput
                    label="Role"
                    source="role"
                    variant="outlined"
                    helperText="Restricts a user to specified sections of AdminDash"
                    validate={required}
                    disabled
                    choices={[{ id: 'admin', name: 'Admin' }]}
                />
            )*/}
        </>
    )

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={userListActionStyles.root}
        >
            <Typography>
                {' '}
                {
                    //TODO: IDK why we have this with paging on the grid
                }
                {`Showing all users (${data?.length})`}{' '}
            </Typography>
            <BaseDialog
                initialValues={{
                    role: 'admin',
                    enabled: true,
                }}
                openDialogButtonText="Add new user"
                dialogTitle="Add New User"
                dialogContentText="We'll email login info and ask for a password change."
                dialogContentFields={createDialogInputFields}
                dialogLauncherClass={'createButton'}
                closeDialogButtonText="Cancel"
                SubmitButton={(props) => <UserCreateButton {...props} />}
            />
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    isParentOrg: state.org.isParentOrg,
})

/**
 *
 * @param props
 * @returns {*}
 */
export default connect(mapStateToProps)((props) => {
    const { data: groups, isLoading: groupsLoading } = useGetList('groups')

    if (groupsLoading) {
        return <LinearProgress />
    }
    return (
        <BaseList
            resource="users"
            basePath="/users"
            perPage={SUBGRID_RECORDS_PER_PAGE}
            actions={
                <UserListActions
                    groups={groups}
                    isParentOrg={props.isParentOrg}
                />
            }
            TopToolbar={null}
            sort={{ field: 'username', order: 'ASC' }}
        >
            <UserDatagrid
                userId={props.userId}
                empty={
                    <EmptyState
                        emptyStateText={
                            'Welcome to Pandium!\nThere are no users yet!'
                        }
                    />
                }
            />
        </BaseList>
    )
})
