import React from 'react'
import BaseList from '../../List/BaseList'
import BaseDatagrid from '../../../react_admin/BaseDatagrid'
import makeStyles from '@mui/styles/makeStyles'
import { TextField as RATextField, FunctionField } from 'react-admin'
import ListTopToolbar from '../../List/ListTopToolbar'
import PartnerFilterToolbar from './PartnerFilterToolbar'
import LinkField from '../../../LinkField'
import CreateButton from '../../../react_admin/CreateButton'
import { PARTNERS_PER_PAGE } from '../../../../appConfigs'

const usePartnerDatagridStyles = makeStyles({
    thumbnailWithName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& img': {
            height: '40px',
            width: '40px',
            paddingRight: '2px',
        },
    },
})
const PartnerDatagrid = (props) => {
    const classes = usePartnerDatagridStyles()
    return (
        <BaseDatagrid rowClick={'show'} bulkActionButtons={false}>
            <FunctionField
                source="name"
                render={(record) => (
                    <div className={classes.thumbnailWithName}>
                        {record.settings.secondaryLogo && (
                            <img src={record.settings.secondaryLogo} alt="" />
                        )}{' '}
                        {record.name}{' '}
                    </div>
                )}
                label="Organization"
            />
            <RATextField
                source="primaryUser.email"
                label="Contact"
                sortable={false}
            />
            <RATextField
                source="tenantsCount"
                label="Tenants"
                //TODO: make api sort by tenants_count and integrations_count
                sortable={false}
            />
            <RATextField
                source="integrationsCount"
                label="Integrations"
                sortable={false}
            />
            <LinkField
                className="detailLink"
                basePath="partners"
                redirect="show"
                sortable={false}
                variant="outlined"
            />
        </BaseDatagrid>
    )
}

const usePartnerToolBarStyles = makeStyles({
    buttonDiv: {
        width: '300px',
        display: 'flex',
        justifyContent: 'space-between',
    },
})
const PartnerTopToolbar = () => {
    const classes = usePartnerToolBarStyles()
    return (
        <ListTopToolbar pageTitle="Partners">
            <div className={classes.buttonDiv}>
                <CreateButton
                    resource="partners"
                    className="clearButton"
                    label="Invite"
                    queryString={'?invitePartner=true'}
                    helpText="For partner approval, they must fill out a form upon initial sign-in."
                />
                <CreateButton
                    resource="partners"
                    className="createButton"
                    helpText="Partners will be automatically created, approved, and ready to submit integrations."
                />
            </div>
        </ListTopToolbar>
    )
}

export default ({ namespace, ...props }) => {
    return (
        <>
            <BaseList
                {...props}
                TopToolbar={PartnerTopToolbar}
                filters={<PartnerFilterToolbar />}
                filter={{ namespace: namespace, org_type: 'PARTNER' }}
                actions={null}
                bulkActionButtons={null}
                perPage={PARTNERS_PER_PAGE}
            >
                <PartnerDatagrid />
            </BaseList>
        </>
    )
}
