import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNotify, useUpdate, Confirm } from 'react-admin'
import { useForm, FormProvider } from 'react-hook-form'
import { get } from 'lodash-es'
import PandiForm from '../../themeConfig/PandiForm'
import { passwordForm } from './UserFormSchema'

const usePasswordFormActionStyles = makeStyles({
    button: {
        width: '240px',
        margin: '0 2px',
        padding: '0 12px',
    },
})

const PasswordFormActions = ({ values, record, pristine, errors }) => {
    const classes = usePasswordFormActionStyles()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [updateUserPassword] = useUpdate()
    const notify = useNotify()

    const updatePassword = () => {
        updateUserPassword(
            'users',
            {
                id: record.id,
                data: {
                    ...record,
                    action: 'update-password',
                    password: values.newPassword,
                    currentPassword: values.currentPassword,
                },
            },
            {
                onSuccess: () => {
                    setOpenConfirm(false)
                    notify('Password Updated Successfully!')
                },
                onFailure: (error) => {
                    setOpenConfirm(false)
                    if (error.status === 403) {
                        notify(
                            'Password Failed to Update: Invalid Credentials',
                            {
                                type: 'warning',
                            }
                        )
                    } else {
                        notify(
                            'Password Failed to Update: ' + error['message'],
                            {
                                type: 'warning',
                            }
                        )
                    }
                    console.debug('Error: ', get(error, 'message', error))
                },
            }
        )
    }

    const disabled = pristine || Object.keys(errors).length > 0

    return (
        <Grid className={classes.button}>
            <Button
                disabled={disabled}
                onClick={() => setOpenConfirm(true)}
                className={'filledButton'}
            >
                Change your password
            </Button>
            <Confirm
                isOpen={openConfirm}
                title="Change Password"
                content="Are you sure you want to change your password?"
                onConfirm={() => updatePassword()}
                onClose={() => setOpenConfirm(false)}
                confirmColor={'#626FFC'}
            />
        </Grid>
    )
}

const validateUserPasswordChange = (values) => {
    const errors = {}
    if (!values.currentPassword) {
        errors.currentPassword = "Password can't be blank."
    }

    if (!values.newPassword) {
        errors.newPassword = "Password can't be blank."
    }

    if (!values.confirmPassword) {
        errors.confirmPassword = 'Please confirm password.'
    }
    if (
        values.newPassword === values.currentPassword &&
        values.currentPassword
    ) {
        errors.newPassword =
            'New password cannot be the same as current password.'
    }
    if (
        values.newPassword &&
        values.confirmPassword &&
        values.newPassword !== values.confirmPassword
    ) {
        errors.confirmPassword = 'Password does not match.'
    }

    return { values: values, errors: errors }
}

const usePasswordSectionStyles = makeStyles({
    root: {
        margin: '6px 0 0 0',
    },
    inputField: {
        width: '100%',
        margin: '0',
    },
})
export default ({ record }) => {
    const classes = usePasswordSectionStyles()
    const methods = useForm({
        resolver: validateUserPasswordChange,
        mode: 'onBlur',
    })
    return (
        <Grid item xs>
            <FormProvider {...methods}>
                <Grid
                    className={classes.root}
                    container
                    spacing={0}
                    direction="row"
                >
                    <Grid item xs={6}>
                        <PandiForm
                            schema={[...passwordForm]}
                            values={methods.getValues()}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <PasswordFormActions
                            values={methods.getValues()}
                            pristine={!methods.formState.isDirty}
                            errors={methods.formState.errors}
                            record={record}
                        />
                    </Grid>
                </Grid>
            </FormProvider>
        </Grid>
    )
}
