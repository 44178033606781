import React, { useEffect, useState } from 'react'
import { useConnectorsList } from '../../../common'
import CreateButton from '../../../react_admin/CreateButton'
import { trimEnvPrefix } from '@thefront/pandipackV2'
import { isEmpty, get, find } from 'lodash-es'
import TenantDataGrid from './TenantDataGrid'
import TenantFilterToolbar from './TenantFilterToolbar'
import TopToolbar from '../../List/ListTopToolbar'
import TenantBulkActionsButtons from '../../List/BulkEdit/TenantBulkActionButtons'
import { TENANTS_PER_PAGE } from '../../../../appConfigs'
import BaseList from '../../List/BaseList'
import { EmptyState } from '../../../react_admin/EmptyState'
import { useGetList } from 'react-admin'
import { connect } from 'react-redux'

const TenantList = (props) => {
    const { namespace, isSuperUser, ...rest } = props
    const pandiumFilter = !isSuperUser
        ? { integration__type__not_equal: 'PANDIUM' }
        : {}
    const { connectors } = useConnectorsList()
    const [accountConnector, setAccountConnector] = useState('')
    const [uidDisplay, setUidDisplay] = useState('')
    const account = trimEnvPrefix(namespace)

    const { loading: integrationsLoading, data: integrations } = useGetList(
        'integrations',
        {
            pagination: { page: 1, perPage: 500 },
        }
    )

    const customerColumnPath = `connectedUsers.${account}.attributes.${account}.${uidDisplay}`
    useEffect(() => {
        if (!isEmpty(connectors) && accountConnector === '') {
            const connector = find(connectors, ['id', account])
            setAccountConnector(connector)
            setUidDisplay(
                get(connector, 'metadata.user_identifier.display', '')
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectors, account])

    const TenantTopToolbar = () => (
        <TopToolbar pageTitle="Tenants">
            <CreateButton className="createButton" resource={'tenants'} />
        </TopToolbar>
    )

    return (
        <BaseList
            perPage={TENANTS_PER_PAGE}
            sort={{ field: 'createdDate', order: 'DESC' }}
            filters={
                <TenantFilterToolbar
                    uidDisplay={uidDisplay}
                    customerColumnPath={customerColumnPath}
                    {...props}
                    alwaysOn
                    isSuperUser={isSuperUser}
                />
            }
            filter={pandiumFilter}
            actions={null}
            TopToolbar={TenantTopToolbar}
            title=""
            {...rest}
        >
            <TenantDataGrid
                uidDisplay={uidDisplay}
                customerColumnPath={customerColumnPath}
                bulkActionButtons={
                    <TenantBulkActionsButtons
                        integrations={integrations}
                        integrationsLoading={integrationsLoading}
                    />
                }
                empty={
                    <EmptyState
                        emptyStateText={
                            'Welcome to Pandium!\nCreate a tenant to view and manage activity.'
                        }
                    />
                }
            />
        </BaseList>
    )
}

const mapStateToProps = (state) => {
    return {
        isSuperUser: state.org.isSuperUser,
    }
}

export default connect(mapStateToProps)(TenantList)
